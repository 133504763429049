import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { themeStyles } from '../utils/theme'

const styles = {
  container: {
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
  }
}

export const CustomerReviewTemplate = ({
  content,
  contentComponent,
  helmet,
  customerName,
  customerFrom,
  reviewPlatform,
  numberOfStars,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section" css={styles.container}>
      {helmet || ''}
      <p>
        {customerName}
        {customerFrom}
        {reviewPlatform}
        {numberOfStars}
      </p>
      <PostContent content={content} />
    </section>
  )
}

CustomerReviewTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  customerName: PropTypes.string,
  customerFrom: PropTypes.string,
  reviewPlatform: PropTypes.string,
  numberOfStars: PropTypes.string,
  date: PropTypes.string,
  helmet: PropTypes.object,
}

const CustomerReview = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <CustomerReviewTemplate
        content={post.htmlAst}
        contentComponent={HTMLContent}
        customerName={post.frontmatter.customerName}
        customerFrom={post.frontmatter.customerFrom}
        reviewPlatform={post.frontmatter.reviewPlatform}
        numberOfStars={post.frontmatter.numberOfStars}
        date={post.frontmatter.date}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  )
}

CustomerReview.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default CustomerReview

export const pageQuery = graphql`
  query CustomerReviewByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        templateKey
        customerName
        customerFrom
        reviewPlatform
        numberOfStars
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
